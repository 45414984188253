exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-preise-js": () => import("./../../../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-pages-produkte-js": () => import("./../../../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-templates-produkt-js": () => import("./../../../src/templates/produkt.js" /* webpackChunkName: "component---src-templates-produkt-js" */)
}

